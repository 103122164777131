// Styles SCSS
import '../sass/home.scss';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import jQuery from 'jquery';

import 'slick-carousel';

// pushbar
import Pushbar from './utils/pushbar';

// SVG polyfiil
import 'svgxuse';

// Slider Bandeau

jQuery(document).ready(() => {
	setTimeout(function() {
		$('.bandeau').slick({
			lazyLoad: 'ondemand',
			autoplay: true,
			autoplaySpeed: 5000,
			dots: false,
			infinite: true,
			arrows: false
		});
	}, 500);

	const pushbar = new Pushbar({
		blur: true,
		overlay: true
	});
});
